import React from 'react'
import Layout from "../layouts/de"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Texto legal - Agencia creativa digital Kitzingen"
    description="Aquí encontrará todos los datos importantes, también me puedes hacer la consulta a mi correo electrónico: mail@lopezdesign.de"
    >
    <Block color="white"
      left={
        <div className="grid__item space--bottom">
          <h1 className="shine">Texto legal</h1>
          <p className="space--none">Lopez Design - Pequeña empresa</p>
          <p>Cristian López</p>

          <p className="space--none">97318 Kitzingen</p>
          <p>Alemania</p>

          <p className="space--none">E-Mail: mail@lopezdesign.de</p>
          <p>Web: lopezdesign.de</p>

          <h5>ID IVA:</h5>
          <p>De acuerdo con el artículo 19, apartado 1, de la UStG (Ley alemana del impuesto sobre el volumen de negocios), no se indica el impuesto sobre el volumen de negocios.</p>
        </div>
      }
    />
  </Layout>
)

export default InfoPage

